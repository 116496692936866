@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0px;
  padding: 0px;
}

.youtube .slick-track {
  width: max-content !important;
  height: 210px;
}

.youtube .slick-slide {
  border-right: 2px solid blue;
}

.youtube .slick-slide > div {
  height: 100%;
}

.youtube .slick-slide > div > div {
  height: 100%;
}

.youtube .slick-slide img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media screen and (max-width: 425px) {
    .youtube .slick-slide {
        border-right: none;
      }
}

@media screen and (max-width: 375px) {
    .youtube .slick-track {
        height: 188px;
      }
}

@media screen and (max-width: 320px) {
    .youtube .slick-track {
        height: 157px;
      }
}
